<template>
  <common-warpper :title="title" en="NEWS DETAIL" :imgSrc="banner">
    <div class="newsDetail">
      <div class="title">{{ pageData.title }}</div>
      <div class="summary">
        <img :src="pageData.icon" />
        <div class="right">
          <p>{{ pageData.summary }}</p>
          <span>{{ pageData.createTime }}</span>
        </div>
      </div>
      <div class="info" v-if="pageData.content">
        <div v-html="pageData.content"></div>
      </div>
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/home/banner2.jpg";
import { newsDetail } from "@/api/index";

export default {
  name: "NewsDetail",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      pageData: {},
      title: "动态详情",
    };
  },
  created() {
    if (this.$route.query.name) {
      this.title = `${this.$route.query.name}详情`;
    }
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      newsDetail(id).then((res) => {
        this.pageData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.newsDetail {
  .title {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .summary {
    padding: 20px 0;
    display: flex;
    img {
      width: 200px;
      height: 120px;
      object-fit: cover;
    }
    .right {
      flex: 1;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: #666;
      }
      span {
        color: #999;
      }
    }
  }
}
</style>
